import { Component, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { ErrorModalConstructorComponent } from '../../../../error-modal/error-modal.component';
import { AlertService } from 'src/app/modules/ui-kit/alert/alert.service';

@Component({
  selector: 'app-image-fields',
  templateUrl: './image-fields.component.html',
  styleUrls: ['./image-fields.component.scss']
})
export class ImageFieldsComponent implements OnInit {

  constructor(
    public readonly controlContainer: ControlContainer,
    public dialog: MatDialog,
    private readonly alert: AlertService
  ) { }
  public file_exist = false;

  ngOnInit(): void {
    this.file_exist = this.file instanceof File || this.file_id;

  }

  public get control(): AbstractControl<any, any> | null {
    return this.controlContainer.control;
  }

  public get file() {
    return this.control?.get('file')?.value;
  }

  public get file_id() {
    return this.control?.get('file_id')?.value;
  }

  public onChange(file_list: FileList | null) {
    const MAX_ALLOWABLE_SIZE = 1_000_000;
    const file = file_list?.item(0);

    this.file_exist = file instanceof File || this.file_id;

    if (file) {
      if (file.size > MAX_ALLOWABLE_SIZE) {
        this.file_exist = false;
        this.alert.alert({ type: 'error', message: 'Image size to big ' })
        return
      }

      this.control?.patchValue({ file });

      this.control?.get('alt')?.setValidators([Validators.required]);
      this.control?.get('title')?.setValidators([Validators.required]);

    }
  }

  public reset() {
    const file_id = this.control?.get('file_id')
    this.file_exist = false;
    this.control?.reset({ ...this.control.value, file: null, ...(file_id && { file_id: null }) });

    this.control?.get('alt')?.removeValidators(Validators.required);
    this.control?.get('title')?.removeValidators(Validators.required);
  }
}
