import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { ProtectionLevels } from 'src/app/enum/protection-levels.enum';
import { MainLayoutComponent } from 'src/app/layout/main/main.component';
import { permissions } from 'src/app/layout/main/permissions';
import { AuthGuard } from 'src/app/modules/authorization/guard/auth.guard';
import { RoleGuard } from 'src/app/modules/authorization/guard/role.guard';
import { LoadingModule } from 'src/app/modules/loading/loading.module';

import { ConstructorComponent } from '../../../common/constructor/component/constructor.component';
import { ConstructorModule } from '../../../common/constructor/constructor.module';
import { AllowedTypeConstructedPageGuard } from '../../../common/constructor/guard/allowed-type-constructed-page.guard';
import { ConstructedPageExistGuard } from '../../../common/constructor/guard/constructed-page-exist.guard';
import { UpdateConstructedPageService } from './services/update-constructed-page.service';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatTabsModule,
    LoadingModule,
    ConstructorModule.inversion(UpdateConstructedPageService),
    RouterModule.forChild([
      {
        path: 'slk-admin/:company-id/:type/edit/:page-id',
        loadChildren: () =>
          import('./update-constructed-pages.module').then(
            (m) => m.UpdateConstructedPageModule
          ),
      },
      {
        path: '',
        component: MainLayoutComponent,
        children: [
          {
            path: '',
            component: ConstructorComponent,
            data: { headline: 'Edit' },
          },
        ],
        canActivate: [
          AuthGuard,
          RoleGuard,
          AllowedTypeConstructedPageGuard,
          ConstructedPageExistGuard,
        ],
        data: {
          protection: ProtectionLevels.AUTHORIZED,
          error_href: '/',
          acceptable_roles: permissions.marketing,
        },
      },
    ]),
  ],
  declarations: [],
  providers: [],
})
export class UpdateConstructedPageModule {}
