import { gql } from 'apollo-angular';
import { ConstructedPage } from 'src/app/entity/constructed-pages/constructed-page.entity';
import { ConstructedPageType } from 'src/app/enum/constructed-page-type.enum';

export const GET_CONSTRUCTED_PAGES = gql`
  query GetConstructedPages(
    $constructed_page_company_id: ID
    $is_posted: Boolean
    $type: ConstructedPageType
  ) {
    getConstructedPages(
      is_posted: $is_posted
      type: $type
      constructed_page_company_id: $constructed_page_company_id
    ) {
      id
      type
      post_date
      is_posted

      blocks {
        phone
        body_text
        constructed_page_id
        constructed_photo_id
        description
        first_list
        headline
        id
        last_list
        last_text
        list
        photo {
          alt
          file {
            format
            id
          }
          file_id
          id
          title
        }
        position_block
        styles
        text
        text_left
        text_right
        type_block
      }
      meta_info {
        categoric
        constructed_page_id
        date_when_will_post_blog
        global_script
        id
        meta_tag_description
        meta_tag_title
        schema_script
        url
      }
      preview {
        constructed_page_id
        constructed_photo_id
        date
        description
        id
        headline
        type_block
        photo {
          alt
          file {
            format
            id
          }
          file_id
          id
          title
        }
      }
    }
  }
`;

export interface IGetConstructedPagesResponse {
  getConstructedPages: ConstructedPage[];
}

export interface IGetConstructedPagesDto {
  is_posted?: boolean;
  pagination: {
    skip: number;
    take: number;
  };
  type?: ConstructedPageType;
  constructed_page_company_id: string;
}
