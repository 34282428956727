<div class="coverFirstBlogComponent">
  <div
    class="FirstBlogComponentBg"
    [style.--url]="
      data.photo.file_id | getImageUrlByPhotoID : data.photo.file : true
    "
  >
    <div class="FirstBlogComponent">
      <h1>{{ data.headline }}</h1>
      <hr class="hrYellow" />
      <p class="phone">{{ data.phone }}</p>
    </div>
  </div>

  <div class="coverText">
    <div class="text" *ngFor="let text of description" [innerHTML]="text"></div>
  </div>
</div>
