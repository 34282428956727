import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl, ControlContainer } from '@angular/forms';
import { Subscription } from 'rxjs';
import { User } from 'src/app/entity/user.entity';
import { AuthorizationService } from 'src/app/modules/authorization/authorization.service';

@Component({
  selector: 'app-main-block-fields',
  templateUrl: './main-block-fields.component.html',
  styleUrls: ['./main-block-fields.component.scss'],
})
export class MainBlockFieldsComponent implements OnInit {
  @Output() removeItem = new EventEmitter<number>();

  public user: User;

  private current_user$: Subscription;

  constructor(
    public readonly controlContainer: ControlContainer,
    private readonly authService: AuthorizationService
  ) {}

  public removeBlock() {
    this.removeItem.emit();
  }

  public ngOnInit() {
    this.current_user$ = this.authService
      .getCurrentUser()
      .subscribe(({ data, loading }) => {
        this.user = data.getCurrentUser;
        console.log(data.getCurrentUser);
      });
  }

  public get email() {
    console.log(this.user);

    return this.user.email;
  }
  // public ngOnDestroy(): void {
  //   this.current_user$.unsubscribe();
  // }

  public get control(): AbstractControl<any, any> | null {
    return this.controlContainer.control;
  }
}
