import { gql } from 'apollo-angular';
import { User } from 'src/app/entity/user.entity';

export const GET_CURRENT_USER = gql`
  query getCurrentUser {
    getCurrentUser {
      id
      role
      name
      email
    }
  }
`;

export interface IGetCurrentResponse {
  getCurrentUser: User;
}
