import { gql } from 'apollo-angular';
import { ConstructedPage } from 'src/app/entity/constructed-pages/constructed-page.entity';

export const GET_CONSTRUCTED_PAGE_BY_ID = gql`
  query getConstructedPageById($id: ID!) {
    getConstructedPageById(id: $id) {
      blocks {
        phone
        body_text
        constructed_page_id
        constructed_photo_id
        description
        first_list
        headline
        id
        last_list
        last_text
        list
        photo {
          alt
          file_id
          id
          title
        }
        position_block
        styles
        text
        text_left
        text_right
        type_block
      }
      id
      is_posted
      meta_info {
        country
        salary
        categoric
        constructed_page_id
        date_when_will_post_blog
        global_script
        id
        meta_tag_description
        meta_tag_title
        schema_script
        url
        name
        state
        map_link
        redirect_url
      }
      post_date
      preview {
        constructed_page_id
        constructed_photo_id
        date
        description
        experience
        id
        headline
        type_block
        photo {
          alt
          file {
            format
            id
          }
          file_id
          id
          title
        }
      }
      type
    }
  }
`;

export interface IGetConstructedPageByIdDto {
  id: string;
}

export interface IGetConstructedPageByIdResponse {
  getConstructedPageById: ConstructedPage;
}
