<!-- <dropdown name="Meta tags and scripts"> -->
<form [formGroup]="$any(control)">
  <mat-form-field>
    <mat-label> Meta tag title </mat-label>
    <input
      matInput
      type="text"
      placeholder="Type tag title headline here"
      formControlName="meta_tag_title"
    />
  </mat-form-field>

  <mat-form-field>
    <mat-label> Meta tag description </mat-label>
    <textarea
      matInput
      type="text"
      cdkTextareaAutosize
      placeholder="Type meta tag description here"
      formControlName="meta_tag_description"
    ></textarea>
  </mat-form-field>

  <mat-form-field>
    <mat-label> City name </mat-label>
    <input
      matInput
      type="text"
      placeholder="Type city name here"
      formControlName="name"
    />
  </mat-form-field>

  <mat-form-field>
    <mat-label>State</mat-label>

    <mat-select placeholder="Select state" formControlName="state">
      <mat-option value="CT">CT</mat-option>
      <mat-option value="NY">NY</mat-option>
      <mat-option value="AZ">AZ</mat-option>
      <mat-option value="RI">RI</mat-option>
      <mat-option value="TX">TX</mat-option>
      <mat-option value="IL">IL</mat-option>
      <mat-option value="FL">FL</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>URL for Location</mat-label>
    <input
      matInput
      type="text"
      placeholder="Type URL here"
      formControlName="url"
    />
  </mat-form-field>

  <mat-form-field>
    <mat-label> Global script </mat-label>
    <textarea
      matInput
      type="text"
      cdkTextareaAutosize
      placeholder="Type global scripts here"
      formControlName="global_script"
    ></textarea>
  </mat-form-field>

  <mat-form-field>
    <mat-label> Redirect url </mat-label>
    <input
      matInput
      type="text"
      placeholder="Type redirect url here"
      formControlName="redirect_url"
    />
  </mat-form-field>

  <mat-form-field>
    <mat-label> Schema script </mat-label>
    <textarea
      matInput
      type="text"
      cdkTextareaAutosize
      placeholder="Type schema scripts here"
      formControlName="schema_script"
    ></textarea>
  </mat-form-field>

  <mat-form-field>
    <mat-label> Map link </mat-label>
    <input
      matInput
      type="text"
      placeholder="Type city name here"
      formControlName="map_link"
    />
  </mat-form-field>
</form>
<!-- </dropdown> -->
