<form [formGroup]="$any(control)">
  <mat-form-field>
    <mat-label> Headline </mat-label>
    <input
      matInput
      type="text"
      placeholder="Type headline here"
      formControlName="headline"
    />
  </mat-form-field>

  <mat-form-field>
    <mat-label> Description </mat-label>
    <textarea
      cdkTextareaAutosize
      matInput
      placeholder="Type description here"
      formControlName="description"
    ></textarea>
  </mat-form-field>

  <mat-form-field>
    <mat-label> Phone number </mat-label>
    <input
      matInput
      type="text"
      placeholder="Type phone number here"
      formControlName="phone"
    />
  </mat-form-field>

  <app-image-fields formGroupName="photo" />
</form>
