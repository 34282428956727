import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

interface MainBlockData {
  phone: string;
  headline: string;
  description: string;
  photo: {
    file: File;
    file_id: string;
  };
}

@Component({
  selector: 'app-main-block',
  templateUrl: './main-block.component.html',
  styleUrls: ['./main-block.component.scss'],
})
export class MainBlockComponent {
  @Input() data: MainBlockData;
  constructor() {}
  get description() {
    // console.log(this.data.description.split('\n'));

    return this.data.description?.split('\n') || [];
    // return this.data.description|| [];
  }
  get phone() {
    return this.data.phone?.split('\n') || [];
  }
}
