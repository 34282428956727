import { FormControl, FormGroup, Validators } from '@angular/forms';

export enum FormTemplates {
  VACANCY_INFO = 'vacancy_info',
  VACANCY_MAIN_BLOCK = 'vacancy_main_block',
  VACANCY_BODY_BLOCK = 'vacancy_body_block',
  BLOCK_WITH_IMAGE_LEFT = 'block_with_image_left',
  MAIN_BLOCK = 'main_block',
  PRICES_BLOCK = 'prices_block',
  YOU_MAY_ALSO_LIKE = 'you_may_also_like',
  OUR_SERVICES = 'our_services',
  ABOUT_US = 'about_us',
  ABOUT_CITY = 'about_city',
  TWENTY_FOUR_HOUR = 'hour_24_services',
  META_INFOS = 'meta_infos',
  META_INFOS_LOCATION = 'meta_infos_location',
  TESTIMONIALS = 'testimonials',
}

export const blocks = {
  [FormTemplates.META_INFOS]: {
    type_block: {
      value: FormTemplates.META_INFOS,
      validators: [],
    },
    meta_tag_description: {
      value: '',
      validators: [Validators.required],
    },
    meta_tag_title: {
      value: '',
      validators: [Validators.required],
    },
    global_script: {
      value: '',
      validators: [],
    },
    schema_script: {
      value: '',
      validators: [],
    },
    url: {
      value: '',
      validators: [Validators.required],
    },
    categoric: {
      value: '',
      validators: [],
    },
    redirect_url: {
      value: '',
      validators: [],
    },
  },
  [FormTemplates.VACANCY_MAIN_BLOCK]: {
    type_block: {
      value: FormTemplates.VACANCY_MAIN_BLOCK,
      validators: [],
    },
    headline: {
      value: 'vacancy headline',
      validators: [Validators.required],
    },
    description: {
      value: '',
      validators: [Validators.required],
    },
    experience: {
      value: '',
      validators: [],
    },
  },
  [FormTemplates.VACANCY_BODY_BLOCK]: {
    type_block: {
      value: FormTemplates.VACANCY_BODY_BLOCK,
      validators: [],
    },
    headline: {
      value: 'body headline',
      validators: [],
    },
    description: {
      value: 'asdasdasd',
      validators: [],
    },
    first_list: {
      value: 'asdasdasd',
      validators: [],
    },
    body_text: {
      value: 'asdasdasd',
      validators: [],
    },
    list: {
      value: '',
      validators: [],
    },
    last_text: {
      value: '',
      validators: [],
    },
    last_list: {
      value: '',
      validators: [],
    },
    photo: {
      value: {
        file: {
          value: null,
          validators: [],
        },
        alt: {
          value: '',
          validators: [],
        },
        title: {
          value: '',
          validators: [],
        },
      },
      validators: [],
    },
    styles: {
      value: {
        add_top_need_more_info_button: {
          value: false,
          validators: [Validators.required],
        },
        add_bottom_need_more_info_button: {
          value: false,
          validators: [Validators.required],
        },
        first_list_number_list: {
          value: false,
          validators: [Validators.required],
        },
        main_list_number_list: {
          value: false,
          validators: [Validators.required],
        },
        last_list_number_list: {
          value: false,
          validators: [Validators.required],
        },
        body_grid_revers: {
          value: false,
          validators: [Validators.required],
        },
        turn_padding_top: {
          value: true,
          validators: [Validators.required],
        },
        turn_padding_bottom: {
          value: true,
          validators: [Validators.required],
        },
        first_text_grid_revers: {
          value: false,
          validators: [Validators.required],
        },
        body_text_grid_revers: {
          value: false,
          validators: [Validators.required],
        },
        last_text_grid_revers: {
          value: false,
          validators: [Validators.required],
        },
        background_exists: {
          value: false,
          validators: [Validators.required],
        },
        first_list_grid_quantity_columns: {
          value: '1',
          validators: [Validators.required],
        },
        main_list_grid_quantity_columns: {
          value: '1',
          validators: [Validators.required],
        },
        last_list_grid_quantity_columns: {
          value: '1',
          validators: [Validators.required],
        },
      },
      validators: [],
    },
  },
  [FormTemplates.VACANCY_INFO]: {
    type_block: {
      value: FormTemplates.VACANCY_INFO,
      validators: [],
    },
    meta_tag_description: {
      value: '',
      validators: [Validators.required],
    },
    meta_tag_title: {
      value: '',
      validators: [Validators.required],
    },
    // vacancy_title: {
    //   value: '',
    //   validators: [Validators.required],
    // },
    // vacancy_description: {
    //   value: '',
    //   validators: [Validators.required],
    // },
    salary: {
      value: '',
      validators: [],
    },
    country: {
      value: '',
      validators: [Validators.required],
    },
    state: {
      value: '',
      validators: [],
    },
  },
  [FormTemplates.MAIN_BLOCK]: {
    type_block: {
      value: FormTemplates.MAIN_BLOCK,
      validators: [Validators.required],
    },
    headline: {
      value: 'Headline',
      validators: [Validators.required],
    },
    description: {
      value: '',
      validators: [],
    },
    phone: {
      value: '',
      validators: [],
    },
    photo: {
      value: {
        file: {
          value: null,
          validators: [Validators.required],
        },
        alt: {
          value: '',
          validators: [Validators.required],
        },
        title: {
          value: '',
          validators: [Validators.required],
        },
      },
      validators: [],
    },
  },
  [FormTemplates.BLOCK_WITH_IMAGE_LEFT]: {
    type_block: {
      value: FormTemplates.BLOCK_WITH_IMAGE_LEFT,
      validators: [],
    },
    headline: {
      value: 'Headline',
      validators: [],
    },
    description: {
      value: '',
      validators: [],
    },
    first_list: {
      value: '',
      validators: [],
    },
    body_text: {
      value: '',
      validators: [],
    },
    list: {
      value: '',
      validators: [],
    },
    last_text: {
      value: '',
      validators: [],
    },
    last_list: {
      value: '',
      validators: [],
    },
    photo: {
      value: {
        file: {
          value: null,
          validators: [],
        },
        alt: {
          value: '',
          validators: [],
        },
        title: {
          value: '',
          validators: [],
        },
      },
      validators: [],
    },
    styles: {
      value: {
        add_top_need_more_info_button: {
          value: false,
          validators: [Validators.required],
        },
        add_bottom_need_more_info_button: {
          value: false,
          validators: [Validators.required],
        },
        first_list_number_list: {
          value: false,
          validators: [Validators.required],
        },
        main_list_number_list: {
          value: false,
          validators: [Validators.required],
        },
        last_list_number_list: {
          value: false,
          validators: [Validators.required],
        },
        body_grid_revers: {
          value: false,
          validators: [Validators.required],
        },
        turn_padding_top: {
          value: true,
          validators: [Validators.required],
        },
        turn_padding_bottom: {
          value: true,
          validators: [Validators.required],
        },
        first_text_grid_revers: {
          value: false,
          validators: [Validators.required],
        },
        body_text_grid_revers: {
          value: false,
          validators: [Validators.required],
        },
        last_text_grid_revers: {
          value: false,
          validators: [Validators.required],
        },
        background_exists: {
          value: false,
          validators: [Validators.required],
        },
        first_list_grid_quantity_columns: {
          value: '1',
          validators: [Validators.required],
        },
        main_list_grid_quantity_columns: {
          value: '1',
          validators: [Validators.required],
        },
        last_list_grid_quantity_columns: {
          value: '1',
          validators: [Validators.required],
        },
      },
      validators: [],
    },
  },
  [FormTemplates.PRICES_BLOCK]: {
    type_block: {
      value: FormTemplates.PRICES_BLOCK,
      validators: [],
    },
    headline: {
      value: '',
      validators: [Validators.required],
    },
    description: {
      value: '',
      validators: [Validators.required],
    },
    list: {
      value: '',
      validators: [Validators.required],
    },
    body_text: {
      value: '',
      validators: [Validators.required],
    },
    last_text: {
      value: '',
      validators: [Validators.required],
    },
    styles: {
      value: {
        add_top_need_more_info_button: {
          value: false,
          validators: [Validators.required],
        },
        add_bottom_need_more_info_button: {
          value: false,
          validators: [Validators.required],
        },
      },
      validators: [],
    },
  },
  [FormTemplates.YOU_MAY_ALSO_LIKE]: {
    type_block: {
      value: FormTemplates.YOU_MAY_ALSO_LIKE,
      validators: [],
    },
    headline: {
      value: 'You may also like',
      validators: [Validators.required],
    },
    list: {
      value:
        'the-benefits-of-hiring-a-professional-home-security-company\nthe-benefits-of-hiring-a-professional-home-security-company\nthe-benefits-of-hiring-a-professional-home-security-company',
      validators: [Validators.required],
    },
  },
  [FormTemplates.META_INFOS_LOCATION]: {
    type_block: {
      value: FormTemplates.META_INFOS_LOCATION,
      validators: [],
    },
    meta_tag_title: {
      value: '',
      validators: [Validators.required],
    },

    meta_tag_description: {
      value: '',
      validators: [Validators.required],
    },
    global_script: {
      value: '',
      validators: [],
    },
    schema_script: {
      value: '',
      validators: [],
    },
    name: {
      value: '',
      validators: [Validators.required],
    },
    state: {
      value: '',
      validators: [Validators.required],
    },
    url: {
      value: '',
      validators: [Validators.required],
    },
    redirect_url: {
      value: '',
      validators: [],
    },
    map_link: {
      value: '',
      validators: [],
    },
  },
  [FormTemplates.ABOUT_US]: {
    type_block: {
      value: FormTemplates.ABOUT_US,
      validators: [],
    },
    headline: {
      value: 'A few words about Sure Lock & Key',
      validators: [],
    },
    description: {
      value: '',
      validators: [],
    },
  },
  [FormTemplates.ABOUT_CITY]: {
    type_block: {
      value: FormTemplates.ABOUT_CITY,
      validators: [],
    },
    headline: {
      value: '',
      validators: [],
    },
    description: {
      value: '',
      validators: [],
    },
  },
  [FormTemplates.OUR_SERVICES]: {
    type_block: {
      value: FormTemplates.OUR_SERVICES,
      validators: [],
    },
    description: {
      value: '',
      validators: [],
    },
    list: {
      value: '',
      validators: [Validators.required],
    },
  },
  [FormTemplates.TWENTY_FOUR_HOUR]: {
    type_block: {
      value: FormTemplates.TWENTY_FOUR_HOUR,
      validators: [],
    },
    headline: {
      value: '',
      validators: [],
    },
    description: {
      value: '',
      validators: [],
    },
    list: {
      value: `Lock repair, replacement, and installation \nCar key copy and duplication \nHome and house lockout services \nHome alarm systems \nEmergency car lockout services \nMotorcycle unlock services \nBroken key extraction \nRekeying services`,
      validators: [],
    },
  },
  [FormTemplates.TESTIMONIALS]: {
    type_block: {
      value: FormTemplates.TESTIMONIALS,
      validators: [],
    },
    description: {
      value: '',
      validators: [],
    },
  },
};
